//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import { addCompany } from "@/api/partner";
import { fetchMemberList } from "@/api/member";
import "@/utils/global.js";
export default {
  name: "CompanyEdit",
  data: function data() {
    return {
      title: "添加公司",
      dialogVisible: false,
      company: {
        name: "",
        type: "",
        owner_user_id: "",
        contact_name: "",
        contact_number: "",
        auto_approval: 0,
        is_withdraw: 0,
        approval_amount: 0,
        remark: ''
      },
      companyType: _global.companyType,
      //搜索用户
      memberLoading: false,
      memberOptions: [],
      rules: {
        name: [{
          required: true,
          message: "公司名称不能为空"
        }],
        type: [{
          required: true,
          message: "请选择类型"
        }],
        owner_user_id: [{
          required: true,
          message: "请选择公司负责人"
        }],
        contact_name: [{
          required: true,
          message: "联系人不能为空"
        }],
        contact_number: [{
          required: true,
          message: "联系人手机不能为空"
        }]
      },
      submitLoading: false
    };
  },
  methods: {
    showAdd: function showAdd() {
      var _this = this;

      this.title = "添加公司";
      this.dialogVisible = true;
      this.$nextTick(function () {
        _this.$refs["companyForm"].clearValidate();
      });
    },
    showEdit: function showEdit(company) {
      var _this2 = this;

      this.title = "编辑公司";
      this.company = company;
      this.dialogVisible = true;
      this.memberOptions.push(company.owner);
      this.remoteMember(company.owner.mobile);
      this.$nextTick(function () {
        _this2.$refs["companyForm"].clearValidate();
      });
    },
    //搜索用户
    remoteMember: function remoteMember(query) {
      var _this3 = this;

      if (query !== "") {
        this.memberLoading = true;
        setTimeout(function () {
          _this3.memberLoading = false;
          var data = {
            keyword: query
          };
          fetchMemberList(data).then(function (res) {
            _this3.memberOptions = res.data;
          });
        }, 100);
      } else {
        this.memberOptions = [];
      }
    },
    handleSubmit: function handleSubmit() {
      var _this4 = this;

      if (this.submitLoading == true) {
        return;
      }

      this.submitLoading = true;
      addCompany(this.company).then(function (res) {
        console.log(res); // 成功之后刷新或
        // this.dataList.unshift('返回的数据')

        _this4.$message({
          type: "success",
          message: _this4.title + "成功"
        });

        _this4.dialogVisible = false;
        _this4.submitLoading = false;

        _this4.$emit('fetch-data');
      }).catch(function (err) {
        _this4.submitLoading = false;

        _this4.$message({
          type: "error",
          message: err.data["message"]
        });
      });
      return false;
    },
    close: function close() {
      this.$refs['companyForm'].resetFields();
      this.dialogVisible = false;
      this.company = {
        name: "",
        type: "",
        owner_user_id: "",
        contact_name: "",
        contact_number: "",
        auto_approval: 0,
        is_withdraw: 0,
        approval_amount: 0,
        remark: ''
      };
    }
  }
};