var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        { staticClass: "form-container" },
        [
          _c(
            "el-form",
            {
              ref: "searchForm",
              staticClass: "search_form",
              attrs: { model: _vm.searchForm, inline: "" }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "类型选择：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { size: "small" },
                      on: { change: _vm.handleSearch },
                      model: {
                        value: _vm.searchForm.type,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "type", $$v)
                        },
                        expression: "searchForm.type"
                      }
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "" } }, [
                        _vm._v("全部")
                      ]),
                      _c("el-radio-button", { attrs: { label: "agent" } }, [
                        _vm._v("代理")
                      ]),
                      _c("el-radio-button", { attrs: { label: "investor" } }, [
                        _vm._v("投资人")
                      ]),
                      _c("el-radio-button", { attrs: { label: "property" } }, [
                        _vm._v("物业")
                      ]),
                      _c(
                        "el-radio-button",
                        { attrs: { label: "intermediary" } },
                        [_vm._v("居间人员")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "keyword", label: "关键词:" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "270px" },
                    attrs: {
                      clearable: "",
                      placeholder: "会员ID/手机/姓名/昵称"
                    },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.handleSearch($event)
                      }
                    },
                    model: {
                      value: _vm.searchForm.keyword,
                      callback: function($$v) {
                        _vm.$set(_vm.searchForm, "keyword", $$v)
                      },
                      expression: "searchForm.keyword"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", icon: "el-icon-search" },
                      on: { click: _vm.handleSearch }
                    },
                    [_vm._v("搜索")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "batch-container" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-plus" },
              on: { click: _vm.handleAdd }
            },
            [_vm._v("添加公司")]
          )
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "table-container" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableLoading,
                  expression: "tableLoading"
                }
              ],
              attrs: { data: _vm.list, border: "" }
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "id",
                  label: "ID",
                  "header-align": "center",
                  align: "center",
                  width: "80px"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "name",
                  label: "公司名称",
                  "header-align": "center",
                  align: "center",
                  width: "150px"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "div",
                          [
                            _c("div", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(scope.row.name) +
                                  "\n            "
                              )
                            ]),
                            scope.row.type == "agent"
                              ? _c("el-tag", { attrs: { type: "success" } }, [
                                  _vm._v("代理")
                                ])
                              : _vm._e(),
                            scope.row.type == "investor"
                              ? _c("el-tag", { attrs: { type: "warning" } }, [
                                  _vm._v("投资人")
                                ])
                              : _vm._e(),
                            scope.row.type == "property"
                              ? _c("el-tag", { attrs: { type: "warning" } }, [
                                  _vm._v("物业")
                                ])
                              : _vm._e(),
                            scope.row.type == "intermediary"
                              ? _c("el-tag", { attrs: { type: "info" } }, [
                                  _vm._v("居间人员")
                                ])
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "contact_name",
                  label: "联系人",
                  "header-align": "center",
                  align: "center",
                  width: "150px"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "padding-left": "10px",
                              "text-align": "left"
                            }
                          },
                          [
                            _c("div", [
                              _vm._v(
                                "联系人：" + _vm._s(scope.row.contact_name)
                              )
                            ]),
                            _c("div", [
                              _vm._v(
                                "联系方式：" + _vm._s(scope.row.contact_number)
                              )
                            ])
                          ]
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  label: "负责人",
                  "header-align": "center",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "padding-left": "10px",
                              "text-align": "left"
                            }
                          },
                          [
                            _c("div", [
                              _vm._v(
                                "昵称：" + _vm._s(scope.row.owner.nickname)
                              )
                            ]),
                            _c("div", [
                              _vm._v(
                                "姓名：" +
                                  _vm._s(
                                    scope.row.owner.realname
                                      ? scope.row.owner.realname
                                      : "-"
                                  )
                              )
                            ]),
                            _c("div", [
                              _vm._v("手机：" + _vm._s(scope.row.owner.mobile))
                            ])
                          ]
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "current_balance",
                  label: "当前余额",
                  "header-align": "center",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "area",
                  label: "负责区域",
                  "header-align": "center",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "is_withdraw",
                  label: "允许提现",
                  "header-align": "center",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", [
                          _c(
                            "div",
                            [
                              scope.row.is_withdraw == 1
                                ? _c("el-tag", { attrs: { type: "success" } }, [
                                    _vm._v("允许")
                                  ])
                                : _c("el-tag", { attrs: { type: "danger" } }, [
                                    _vm._v("禁止")
                                  ])
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              scope.row.auto_approval == 1
                                ? _c("el-tag", { attrs: { type: "success" } }, [
                                    _vm._v("自动提现")
                                  ])
                                : _c("el-tag", { attrs: { type: "warning" } }, [
                                    _vm._v("手动提现")
                                  ])
                            ],
                            1
                          ),
                          scope.row.auto_approval == 1
                            ? _c("div", [
                                _vm._v(
                                  "\n              批准额度：" +
                                    _vm._s(scope.row.approval_amount) +
                                    "\n            "
                                )
                              ])
                            : _vm._e()
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  label: "桩数据",
                  "header-align": "center",
                  align: "center",
                  width: "120"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "padding-left": "10px",
                              "text-align": "left"
                            }
                          },
                          [
                            _c("div", [
                              _vm._v(
                                "桩总数：" +
                                  _vm._s(scope.row.charging_data.total_num)
                              )
                            ]),
                            _c("div", [
                              _vm._v(
                                "在线桩：" +
                                  _vm._s(scope.row.charging_data.online_num)
                              )
                            ]),
                            _c("div", [
                              _vm._v(
                                "离线桩：" +
                                  _vm._s(scope.row.charging_data.offline_num)
                              )
                            ]),
                            _c("div", [
                              _vm._v(
                                "小区数：" +
                                  _vm._s(scope.row.charging_data.property_num)
                              )
                            ])
                          ]
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  label: "订单数据",
                  "header-align": "center",
                  align: "center",
                  width: "180"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "padding-left": "10px",
                              "text-align": "left"
                            }
                          },
                          [
                            _c("div", [
                              _vm._v(
                                "今日营业额：" +
                                  _vm._s(
                                    scope.row.order_data.today_order_amount
                                  )
                              )
                            ]),
                            _c("div", [
                              _vm._v(
                                "今日订单数：" +
                                  _vm._s(scope.row.order_data.today_order_num)
                              )
                            ]),
                            _c("div", [
                              _vm._v(
                                "今日电量：" +
                                  _vm._s(
                                    scope.row.order_data.today_total_energy
                                  )
                              )
                            ]),
                            _c("div", [
                              _vm._v(
                                "累计电量：" +
                                  _vm._s(scope.row.order_data.total_energy)
                              )
                            ])
                          ]
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "remark",
                  label: "备注",
                  "header-align": "center",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "updated_at",
                  label: "更新时间",
                  "header-align": "center",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "created_at",
                  label: "创建时间",
                  "header-align": "center",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "header-align": "center",
                  align: "center",
                  width: "180px"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.handleEdit(scope.row)
                              }
                            }
                          },
                          [_vm._v("\n            编辑\n          ")]
                        ),
                        _c(
                          "el-button",
                          { attrs: { type: "text", size: "small" } },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  tag: "span",
                                  to: {
                                    path: "/partner/company/member",
                                    query: {
                                      company_id: scope.row.id,
                                      company_name: scope.row.name
                                    }
                                  }
                                }
                              },
                              [_vm._v("员工")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-button",
                          { attrs: { type: "text", size: "small" } },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  tag: "span",
                                  to: {
                                    path: "/partner/company/property",
                                    query: {
                                      company_id: scope.row.id,
                                      company_name: scope.row.name
                                    }
                                  }
                                }
                              },
                              [_vm._v("结算小区")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.handleDelete(scope.row)
                              }
                            }
                          },
                          [_vm._v("\n            删除\n          ")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.totalPage > 0,
            expression: "totalPage > 0"
          }
        ],
        attrs: {
          total: _vm.totalPage,
          page: _vm.searchForm.page,
          limit: _vm.searchForm.per_page
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.searchForm, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.searchForm, "per_page", $event)
          },
          pagination: _vm.fetchList
        }
      }),
      _c("company-edit", {
        ref: "companyEdit",
        on: { "fetch-data": _vm.queryData }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }