var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { title: _vm.title, width: "800px", visible: _vm.dialogVisible },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.close
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "companyForm",
          attrs: {
            "label-position": "right",
            "label-width": "130px",
            model: _vm.company,
            rules: _vm.rules
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "公司名称：", prop: "name" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.company.name,
                  callback: function($$v) {
                    _vm.$set(_vm.company, "name", $$v)
                  },
                  expression: "company.name"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "类型：", prop: "type" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择类型" },
                  model: {
                    value: _vm.company.type,
                    callback: function($$v) {
                      _vm.$set(_vm.company, "type", $$v)
                    },
                    expression: "company.type"
                  }
                },
                _vm._l(_vm.companyType, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "负责区域：", prop: "area" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入负责区域" },
                model: {
                  value: _vm.company.area,
                  callback: function($$v) {
                    _vm.$set(_vm.company, "area", $$v)
                  },
                  expression: "company.area"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "公司负责人：", prop: "owner_user_id" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    remote: "",
                    "reserve-keyword": "",
                    placeholder: "请输入手机号或名字搜索",
                    "remote-method": _vm.remoteMember,
                    clearable: "",
                    loading: _vm.memberLoading
                  },
                  model: {
                    value: _vm.company.owner_user_id,
                    callback: function($$v) {
                      _vm.$set(_vm.company, "owner_user_id", $$v)
                    },
                    expression: "company.owner_user_id"
                  }
                },
                _vm._l(_vm.memberOptions, function(item) {
                  return _c(
                    "el-option",
                    {
                      key: item.id,
                      attrs: {
                        label: item.nickname || item.realname || item.mobile,
                        value: item.id
                      }
                    },
                    [
                      _c("span", { staticStyle: { float: "left" } }, [
                        _vm._v(_vm._s(item.nickname || item.realname))
                      ]),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            float: "right",
                            color: "#8492a6",
                            "font-size": "13px"
                          }
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(item.mobile) +
                              "\n            "
                          )
                        ]
                      )
                    ]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "联系人姓名：", prop: "contact_name" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入联系人姓名" },
                model: {
                  value: _vm.company.contact_name,
                  callback: function($$v) {
                    _vm.$set(_vm.company, "contact_name", $$v)
                  },
                  expression: "company.contact_name"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "联系人手机：", prop: "contact_number" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入联系人手机号码" },
                model: {
                  value: _vm.company.contact_number,
                  callback: function($$v) {
                    _vm.$set(_vm.company, "contact_number", $$v)
                  },
                  expression: "company.contact_number"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "是否允许提现：", prop: "is_withdraw" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.company.is_withdraw,
                    callback: function($$v) {
                      _vm.$set(_vm.company, "is_withdraw", $$v)
                    },
                    expression: "company.is_withdraw"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("允许")]),
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("禁止")])
                ],
                1
              )
            ],
            1
          ),
          _vm.company.is_withdraw == 1
            ? _c(
                "el-form-item",
                { attrs: { label: "自动批准提现：", prop: "auto_approval" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.company.auto_approval,
                        callback: function($$v) {
                          _vm.$set(_vm.company, "auto_approval", $$v)
                        },
                        expression: "company.auto_approval"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("允许")]),
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("禁止")])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.company.auto_approval == 1
            ? _c(
                "el-form-item",
                { attrs: { label: "自动批准金额：", prop: "approval_amount" } },
                [
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      placeholder: "批准金额，低于多少自动批准，0=不限制"
                    },
                    model: {
                      value: _vm.company.approval_amount,
                      callback: function($$v) {
                        _vm.$set(_vm.company, "approval_amount", $$v)
                      },
                      expression: "company.approval_amount"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "备注：" } },
            [
              _c("el-input", {
                staticStyle: { "max-width": "500px" },
                attrs: { clearable: "", placeholder: "" },
                model: {
                  value: _vm.company.remark,
                  callback: function($$v) {
                    _vm.$set(_vm.company, "remark", $$v)
                  },
                  expression: "company.remark"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "footer-container",
          staticStyle: { "padding-top": "10px" }
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitLoading },
              on: { click: _vm.handleSubmit }
            },
            [_vm._v("保存")]
          ),
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取消")])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }