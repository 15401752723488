/* eslint-disable */
var _global = {
  lineStatus: [{
    value: 1,
    label: "在线"
  }, {
    value: 2,
    label: "离线"
  }],
  failState: [{
    value: 1,
    label: "成功"
  }, {
    value: 2,
    label: "失败"
  }],
  channelState: [{
    value: 0,
    label: "其他"
  }, {
    value: 1,
    label: "微信"
  }, {
    value: 2,
    label: "支付宝"
  }, {
    value: 3,
    label: "线下"
  }, {
    value: 4,
    label: "余额"
  }, {
    value: 5,
    label: "云闪付"
  }],
  chargeType: [{
    value: 0,
    label: "电动自行车"
  }, {
    value: 1,
    label: "电动汽车桩"
  }, {
    value: 3,
    label: "电动车充电柜"
  }, {
    value: 4,
    label: "二合一充电桩"
  }, {
    value: 5,
    label: "智能充电插座"
  }],
  billMode: [{
    value: 1,
    label: "按时间计费"
  }, {
    value: 2,
    label: "按电量计费"
  }, {
    value: 3,
    label: "按功率计费"
  }],
  connectMode: [{
    value: 1,
    label: "喻深鸿"
  }, {
    value: 2,
    label: "世峰高科"
  }],
  bindType: [{
    value: "1",
    label: "未绑定"
  }, {
    value: "2",
    label: "已绑定"
  }, {
    value: "3",
    label: "已挂失"
  }],
  orderState: [{
    value: 0,
    label: "待支付"
  }, {
    value: 1,
    label: "发起充电"
  }, {
    value: 2,
    label: "正在充电"
  }, {
    value: 3,
    label: "充电完成"
  }, {
    value: 4,
    label: "充电暂停"
  }, {
    value: 5,
    label: "已退款"
  }, {
    value: 99,
    label: "充电失败"
  }],
  searchOption: [{
    value: 0,
    label: "全部"
  }, {
    value: 1,
    label: "代理商"
  }, {
    value: 2,
    label: "小区"
  }],
  acOption: [{
    value: 0,
    label: "未开始"
  }, {
    value: 1,
    label: "进行中"
  }, {
    value: 2,
    label: "已结束"
  }],
  timeOption: {
    shortcuts: [{
      text: "最近一周",
      onClick: function onClick(picker) {
        var end = new Date();
        var start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
        picker.$emit("pick", [start, end]);
      }
    }, {
      text: "最近一个月",
      onClick: function onClick(picker) {
        var end = new Date();
        var start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
        picker.$emit("pick", [start, end]);
      }
    }, {
      text: "最近三个月",
      onClick: function onClick(picker) {
        var end = new Date();
        var start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
        picker.$emit("pick", [start, end]);
      }
    }]
  },
  timeOption2: {
    shortcuts: [{
      text: "三天",
      onClick: function onClick(picker) {
        var end = new Date();
        var start = new Date();
        end.setTime(start.getTime() + 3600 * 1000 * 24 * 3);
        picker.$emit("pick", [start, end]);
      }
    }, {
      text: "一周",
      onClick: function onClick(picker) {
        var end = new Date();
        var start = new Date();
        end.setTime(start.getTime() + 3600 * 1000 * 24 * 7);
        picker.$emit("pick", [start, end]);
      }
    }, {
      text: "一个月",
      onClick: function onClick(picker) {
        var end = new Date();
        var start = new Date();
        end.setTime(start.getTime() + 3600 * 1000 * 24 * 30);
        picker.$emit("pick", [start, end]);
      }
    }, {
      text: "三个月",
      onClick: function onClick(picker) {
        var end = new Date();
        var start = new Date();
        end.setTime(start.getTime() + 3600 * 1000 * 24 * 90);
        picker.$emit("pick", [start, end]);
      }
    }],
    disabledDate: function disabledDate(time) {
      return time.getTime() < Date.now() - 8.64e7;
    }
  },
  controlState: [{
    value: 0,
    label: "测试"
  }, {
    value: 1,
    label: "库存"
  }, {
    value: 2,
    label: "安装"
  }, {
    value: 3,
    label: "运营"
  }, {
    value: 4,
    label: "返厂"
  }, {
    value: 5,
    label: "废弃"
  }],
  // 类型:agent=代理,investor=投资人,property=物业,intermediary=居间人员
  companyType: [{
    value: 'agent',
    label: "代理"
  }, {
    value: 'investor',
    label: "投资人"
  }, {
    value: 'property',
    label: "物业"
  }, {
    value: 'intermediary',
    label: "居间人员"
  }]
};
window._global = _global;