import request from '@/utils/request';
/**
 *  fetch: 获取
 *  update: 修改
 *  add: 增加
 *  del: 删除
 */
// member 会员管理
// 会员列表

export function fetchMemberList(data) {
  return request({
    url: '/members',
    method: 'get',
    params: data
  });
} // 添加会员

export function addMember(data) {
  return request({
    url: '/members',
    method: 'post',
    data: data
  });
} // 更新会员

export function updateMember(data) {
  return request({
    url: "/members/".concat(data.id),
    method: 'put',
    data: data
  });
} // // 充电卡
// export function fetchMebCardList(data) {
//   return request({
//     url: '',
//     method: '',
//     data
//   })
// }
// 添加会员卡

export function addMebCard(data) {
  return request({
    url: '',
    method: '',
    data: data
  });
} // 导出会员卡列表

export function fetchMebcardExcel(data) {
  return request({
    url: '',
    method: '',
    data: data
  });
} // 充值积分

export function updateIntegra(data) {
  return request({
    url: '/points',
    method: 'put',
    data: data
  });
} // 充值余额

export function updateBalance(data) {
  return request({
    url: '/balance',
    method: 'put',
    data: data
  });
} // 充值管理员列表

export function fetchRefillList(data) {
  return request({
    url: '/topup_manager',
    method: 'get',
    params: data
  });
} // 删除充值管理员

export function delRefill(data) {
  return request({
    url: '/topup_manager',
    method: 'delete',
    data: data
  });
} // 添加充值管理员

export function addRefill(data) {
  return request({
    url: '/topup_manager',
    method: 'post',
    data: data
  });
} // 充值管理员禁启

export function updateRefill(data) {
  return request({
    url: "/topup_manager/".concat(data.id),
    method: 'put',
    data: data
  });
} // 充值优惠

export function fetchEnoughList() {
  return request({
    url: '/coupon',
    method: 'get'
  });
} // 添加优惠

export function addEnough(data) {
  return request({
    url: '/coupon',
    method: 'post',
    data: data
  });
} // 删除优惠

export function delEnough(id) {
  return request({
    url: "/coupon/".concat(id),
    method: 'delete'
  });
} // 充值管理员充值记录

export function fetchRecordList(data) {
  return request({
    url: '/topup_record',
    method: 'get',
    params: data
  });
} // 充值活动列表

export function fetchAcList(data) {
  return request({
    url: '/activity',
    method: 'get',
    params: data
  });
} // 充值活动添加

export function addAc(data) {
  return request({
    url: '/activity',
    method: 'post',
    data: data
  });
} // 充值活动编辑

export function updateAc(data) {
  return request({
    url: "/activity/".concat(data.id),
    method: 'put',
    data: data
  });
} // 充值活动详情

export function fetchAcDetail(id) {
  return request({
    url: "/activity/".concat(id),
    method: 'get'
  });
} // 充值活动删除

export function delAc(id) {
  return request({
    url: "/activity/".concat(id),
    method: 'delete'
  });
} // 会员列表

export function fetchMemberLevels(data) {
  return request({
    url: '/member/levels',
    method: 'get',
    params: data
  });
} // 添加会员等级

export function addMemberLevel(data) {
  return request({
    url: "/member/levels",
    method: "post",
    data: data
  });
} // 更新会员等级

export function updateMemberLevel(data) {
  return request({
    url: "/member/levels/".concat(data.id),
    method: "put",
    data: data
  });
} // 删除会员等级

export function deleteMemberLevel(data) {
  return request({
    url: "/member/levels/".concat(data.id),
    method: "delete"
  });
} // 会员等级记录

export function fetchMemberLevelRecords(data) {
  return request({
    url: '/member/levels/record',
    method: 'get',
    params: data
  });
} // 添加用户会员等级

export function addMemberLevelRecord(data) {
  return request({
    url: "/member/levels/record",
    method: "post",
    data: data
  });
}